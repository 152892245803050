// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js

import { PublicClientApplication, InteractionRequiredAuthError } from '@azure/msal-browser'
import { msalConfig, loginRequest } from './authConfig'
import { b2cPolicies } from './policies'

const myMSALObj = new PublicClientApplication(msalConfig)

let accountId = ''
let username = ''

function setAccount(account) {
  accountId = account.homeAccountId
  username = account.username
}
function selectAccount() {
  /**
   * See here for more info on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */

  const currentAccounts = myMSALObj.getAllAccounts()

  if (currentAccounts.length < 1) {
    return
  } else if (currentAccounts.length > 1) {
    /**
     * Due to the way MSAL caches account objects, the auth response from initiating a user-flow
     * is cached as a new account, which results in more than one account in the cache. Here we make
     * sure we are selecting the account with homeAccountId that contains the sign-up/sign-in user-flow,
     * as this is the default flow the user initially signed-in with.
     */
    const accounts = currentAccounts.filter(
      (account) =>
        account.homeAccountId.toUpperCase().includes(b2cPolicies.names.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims.iss.toUpperCase().includes(b2cPolicies.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === msalConfig.auth.clientId
    )

    if (accounts.length > 1) {
      // localAccountId identifies the entity for which the token asserts information.
      if (accounts.every((account) => account.localAccountId === accounts[0].localAccountId)) {
        // All accounts belong to the same user
        setAccount(accounts[0])
      } else {
        // Multiple users detected. Logout all to be safe.
        signOut()
      }
    } else if (accounts.length === 1) {
      setAccount(accounts[0])
    }
  } else if (currentAccounts.length === 1) {
    setAccount(currentAccounts[0])
  }
}

// in case of page refresh
selectAccount()

function handleResponse(response) {
  /**
   * To see the full list of response object properties, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#response
   */

  if (response !== null) {
    setAccount(response.account)
  } else {
    selectAccount()
  }
}

function signIn(setSuccess) {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  myMSALObj
    .loginPopup(loginRequest)
    .then(handleResponse)
    .then(() => setSuccess())
    .catch((error) => {
      console.error(error)
    })
}

function signOut(setSuccess) {
  /**
   * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
   */

  const logoutRequest = {
    postLogoutRedirectUri: msalConfig.auth.redirectUri,
    mainWindowRedirectUri: msalConfig.auth.redirectUri,
  }

  myMSALObj.logoutPopup(logoutRequest).then(() => setSuccess())
}

async function getTokenPopup(request) {
  /**
   * See here for more information on account retrieval:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  request.account = myMSALObj.getAccountByHomeId(accountId)

  /**
   *
   */
  return myMSALObj
    .acquireTokenSilent(request)
    .then((response) => {
      // In case the response from B2C server has an empty accessToken field
      // throw an error to initiate token acquisition
      if (!response.accessToken || response.accessToken === '') {
        throw new InteractionRequiredAuthError()
      }
      return response
    })
    .catch((error) => {
      console.error('Silent token acquisition fails. Acquiring token using popup. \n', error)
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return myMSALObj
          .acquireTokenPopup(request)
          .then((response) => {
            console.error(response)
            return response
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        console.error(error)
      }
    })
}

export { username, signIn, getTokenPopup, signOut }
